import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "../ui/Container"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Page not found" />
    <Container>
      <h1>This page does not exist</h1>
      <p>Not sure how you got here, but you got nowhere.</p>
    </Container>
  </Layout>
)

export default NotFoundPage
